.form-builder {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: "Segoe UI", sans-serif;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .select {
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
  }
  
  .btn {
    padding: 10px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .btn.remove {
    background-color: #dc3545;
    margin-left: 10px;
  }
  
  .btn.remove:hover {
    background-color: #a71d2a;
  }
  
  .btn.small {
    padding: 6px 12px;
    margin-top: 10px;
  }
  
  .add-field-section {
    margin-top: 30px;
  }
  
  .add-field-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
  }
  
  .field-card {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    margin-top: 20px;
  }
  
  .field-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkbox-label {
    font-size: 14px;
  }
  
  .options-section {
    margin-top: 10px;
  }
  
  .option-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
  
  .camera-section {
    margin-top: 15px;
  }
  
  .condition-section {
    margin-top: 15px;
  }
  
  .condition-input {
    width: calc(50% - 10px);
    margin-left: 5px;
  }
  
  .save-btn {
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-top: 30px;
  }
  
  /* Mobile optimization */
  @media (max-width: 600px) {
    .condition-input {
      width: 100%;
      margin-top: 10px;
    }
  
    .add-field-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .option-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .btn {
      width: 100%;
    }
  }
  