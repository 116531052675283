.form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }
  
  .text-center {
    text-align: center;
  }
  
  .loading {
    padding: 20px;
    text-align: center;
  }
  
  .form-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    font-weight: 600;
    display: block;
    margin-bottom: 6px;
  }
  
  .required {
    color: red;
    margin-left: 4px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
  .form-radio-option,
  .form-checkbox-option {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;
  }
  
  .button {
    background-color: #16a34a;
    color: white;
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #15803d;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
    margin-bottom: 16px;
  }
  